import { useEffect } from 'react';
import isUndefined from 'lodash/isUndefined';
import some from 'lodash/some';
import maxBy from 'lodash/maxBy';
import { parsePrice } from './price';
import { sanitizeCustomerData, sanitizeEmail, sanitizePhoneNumber } from './fb-capi';
import { BuyFromType, OrderPaymentMethod } from 'constant';

export const PAGE_TYPES = {
  EDITORIAL_PAGE: 'Editorial Page',
  SALES_PAGE: 'Sales Page',
  CHECKOUT_PAGE: 'Checkout Page',
  UPSELL_PAGE: 'Upsell Page',
  POLICY_PAGE: 'Policy Page',
  ORDER_SUMMARY_PAGE: 'Order Summary Page',
  ARTICLE_PAGE: 'Article Page',
  LISTICLE_PAGE: 'Listicle Page',
  REVIEW_PAGE: 'Review Page',
  HOME_PAGE: 'Home Page',
};

export const PURCHASE_TYPES = {
  MAIN_OFFER: 'Main Offer',
  UPSELL_OFFER: 'Upsell Offer',
};

/**
 * This function should only be used for firing events imperatively (i.e. as part
 * of a submit handler). If want an event to fire on page load, use `useGtmEvents`.
 *
 * Pushes an event or array of events onto the page's dataLayer array.
 * @param {Object/Array} eventOrEvents - An event object or an array of event objects.
 * i.e.
 * {
 *   event: 'funnel.entrance',
 *   funnelName: 'Keto Sticks Sampler Box - Trip Wire Sales Page',
 *   pageType: 'Sales Page'
 * }
 */
export function event(eventOrEvents) {
  const dataLayer = (window.dataLayer = window.dataLayer || []);
  const events = Array.isArray(eventOrEvents) ? eventOrEvents : [eventOrEvents];
  events.forEach(el => dataLayer.push(el));
}

// Fires GTM events as part of page load.
export function useGtmEvents(eventOrEvents, requiredVars) {
  useEffect(() => {
    const isLoaded = !some(requiredVars, isUndefined);
    if (!isLoaded) return;
    event(eventOrEvents);
    // We do not want to provide eventOrEvents as a hook dependency because
    // it will change each time `usePageView` is called since it defines the
    // event data on each call. We are relying on `requiredVars` to contain
    // the necessary hook dependencies.
    /* eslint-disable-next-line react-hooks/exhaustive-deps */
  }, [...requiredVars]);
}

export function usePageView({ pageType, path, merchantName }) {
  if (pageType && !Object.values(PAGE_TYPES).includes(pageType)) {
    throw new Error(`usePageView: Invalid pageType ${pageType}`);
  }

  const pathWithoutQuerystring = path.split('?')[0];

  const _event = {
    event: 'page_view',
  };

  if (pageType) {
    _event.page_type = pageType;
  }

  if (merchantName) {
    _event.merchant = merchantName;
  }

  useGtmEvents(_event, [pathWithoutQuerystring]);
}

// Uses quantity 1 if not specified on offer (i.e. on page load). Quantity may be specified with
// quantity selector controls.
function getItemValue(item) {
  if (!item) return 0;
  return parsePrice(item.price) * (item.quantity ?? 1);
}

function getHighestValueItem(items) {
  if (!items) return null;
  return maxBy(items, getItemValue);
}

// Determine item with greatest value (quantity * price).
function getHighestValue(items) {
  if (!items) return 0;
  return getItemValue(getHighestValueItem(items));
}

function getTotalValue(items) {
  if (!items) return 0;
  return items.reduce((total, item) => total + parsePrice(item.price) * (item.quantity ?? 1), 0);
}

// Uses quantity 1 if not specified on offer (i.e. on page load). Quantity may be specified with
// quantity selector controls.
function buildOfferItems(offers) {
  if (!offers) return [];

  return offers.map(offer => ({
    item_id: offer?.id,
    item_name: offer?.name,
    affiliation: 'Velocity',
    index: offer?.itemListIndex,
    item_list_id: offer?.itemListId,
    item_brand: offer?.store?.brand?.name,
    price: parsePrice(offer?.price),
    quantity: offer.quantity ?? 1,
  }));
}

function buildLineItems(lineItems) {
  if (!lineItems) return [];

  return lineItems.map(lineItem => ({
    item_id: lineItem?.offerId,
    item_name: lineItem?.name,
    affiliation: 'Velocity',
    coupon: lineItem?.discountCode,
    discount: parsePrice(lineItem?.discountAmount),
    index: lineItem?.itemListIndex,
    item_list_id: lineItem?.itemListId,
    item_brand: lineItem?.brandName,
    price: parsePrice(lineItem?.price),
    quantity: lineItem?.quantity,
  }));
}

export function useFunnelDetail(offerData) {
  const events = [
    { ecommerce: null },
    {
      event: 'dl_view_item',
      ecommerce: {
        currencyCode: offerData?.data?.[0]?.store?.currency,
        detail: {
          actionField: { list: '' },
          products: offerData?.data?.map(offer => ({
            id: offer?.id,
            name: offer?.name,
            price: parsePrice(offer?.price),
            brand: offer?.store?.brand?.name,
          })),
        },
      },
    },
  ];

  if (process.env.NEXT_PUBLIC_GA4_FEATURE_FLAG) {
    // view_item
    events.push({ ecommerce: null });
    events.push({
      event: 'view_item',
      ecommerce: {
        currency: 'USD',
        value: getHighestValue(offerData?.data),
        items: buildOfferItems(offerData?.data),
      },
    });

    // view_item_list
    events.push({ ecommerce: null });
    events.push({
      event: 'view_item_list',
      ecommerce: {
        item_list_id: offerData?.data?.[0]?.itemListId,
        items: offerData?.data?.map(offer => ({
          item_id: offer?.id,
          item_name: offer?.name,
          affiliation: 'Velocity',
          index: offer?.itemListIndex,
          item_brand: offer?.store?.brand?.name,
          price: parsePrice(offer?.price),
          quantity: 1,
        })),
      },
    });
  }

  useGtmEvents(events, [offerData]);
}

// Fired for CTA button clicks in MultiBundle component, offer selection in FunnelCart component,
// or quantity change in CTA component.
// Note: Not fired on initially loaded FunnelCart product selection as this event represents user interation.
export function onSelectItem(offer) {
  if (process.env.NEXT_PUBLIC_GA4_FEATURE_FLAG) {
    event([
      {
        ecommerce: null,
      },
      {
        event: 'select_item',
        ecommerce: {
          items: buildOfferItems([offer]),
        },
      },
    ]);
  }
}

// Fired for buy from selection in FunnelCart component.
export function onSelectBuyFrom(offer, buyFromTarget) {
  if (process.env.NEXT_PUBLIC_GA4_FEATURE_FLAG) {
    const price = buyFromTarget === BuyFromType.AMAZON ? offer?.amazonPrice : offer?.price;
    event([
      {
        ecommerce: null,
      },
      {
        event: 'select_buy_from',
        ecommerce: {
          currency: offer?.store?.currency,
          value: getHighestValue([{ ...offer, price }]),
          buy_from: buyFromTarget,
          items: buildOfferItems([{ ...offer, price }]),
        },
      },
    ]);
  }
}

// Should be fired on sales page anchor link clicks to go to CTA buttons (i.e. StickyBanner)
export function onAddToCart(offerData) {
  // TODO: remove GA3 event after lukas has fully converted to using GA4 events
  event([
    {
      ecommerce: null,
    },
    {
      event: 'dl_add_to_cart',
      ecommerce: {
        currencyCode: offerData?.data?.[0]?.store?.currency,
        add: {
          products: offerData?.data?.map(offer => ({
            id: offer?.id,
            name: offer?.name,
            price: parsePrice(offer?.price),
            brand: offer?.store?.brand?.name,
            quantity: 1,
          })),
        },
      },
    },
  ]);

  if (process.env.NEXT_PUBLIC_GA4_FEATURE_FLAG) {
    const highestValueItem = getHighestValueItem(offerData?.data);
    event([
      {
        ecommerce: null,
      },
      {
        event: 'cta_anchor_click',
        ecommerce: {
          currency: offerData?.data?.[0]?.store?.currency,
          value: getItemValue(highestValueItem),
          // Only include highest value offer
          items: buildOfferItems([highestValueItem]),
        },
      },
    ]);
  }
}

// Should be fired for any sales page CTA clicks. This includes: links to checkout page,
// sticky buy button express pay options. This is not used for external buy buttons (i.e. amazon).
export function onBeginCheckout(offers, paymentMethod = OrderPaymentMethod.CREDIT_CARD) {
  // TODO: remove GA3 event after lukas has fully converted to using GA4 events
  event([
    {
      ecommerce: null,
    },
    {
      event: 'dl_begin_checkout',
      ecommerce: {
        currencyCode: offers[0]?.store?.currency,
        checkout: {
          actionField: {
            step: 1,
          },
          products: offers.map(offer => ({
            id: offer?.id,
            name: offer?.name,
            price: parsePrice(offer?.price),
            brand: offer?.store?.brand?.name,
            quantity: offer?.quantity ?? 1,
          })),
        },
      },
    },
  ]);

  if (process.env.NEXT_PUBLIC_GA4_FEATURE_FLAG) {
    event([
      {
        ecommerce: null,
      },
      {
        event: 'begin_checkout',
        ecommerce: {
          currency: offers[0]?.store?.currency,
          value: getTotalValue(offers),
          buy_from: BuyFromType.VELOCITY,
          payment_type: paymentMethod,
          items: buildOfferItems(offers),
        },
      },
    ]);
  }
}

// Should be fired for any sales page external CTA click (i.e. Amazon)
export function onExternalCheckout(offer, buyFromTarget, price) {
  // TODO: remove GA3 event after lukas has fully converted to using GA4 events
  event([
    {
      ecommerce: null,
    },
    {
      event: 'dl_external_checkout',
      checkout_target: buyFromTarget,
      ecommerce: {
        currencyCode: offer?.store?.currency,
        checkout: {
          actionField: {
            step: 1,
          },
          products: [
            {
              id: offer?.id,
              name: offer?.name,
              price: parsePrice(price),
              brand: offer?.store?.brand?.name,
              quantity: offer?.quantity,
            },
          ],
        },
      },
    },
  ]);

  if (process.env.NEXT_PUBLIC_GA4_FEATURE_FLAG) {
    event([
      {
        ecommerce: null,
      },
      {
        event: 'begin_checkout',
        ecommerce: {
          currency: offer?.store?.currency,
          value: getHighestValue([{ ...offer, price }]),
          buy_from: buyFromTarget,
          items: buildOfferItems([{ ...offer, price }]),
        },
      },
    ]);
  }
}

// Should be fired when user identified with email in checkout form, or on express checkout button
// click (including sticky buy button express methods)
export function onAddContactInfo({
  cartData,
  paymentMethod = OrderPaymentMethod.CREDIT_CARD,
  checkoutLocation,
  email,
  leadId,
}) {
  // TODO: remove GA3 event after lukas has fully converted to using GA4 events
  event([
    {
      ecommerce: null,
    },
    {
      event: 'dl_add_contact_info',
      ecommerce: {
        currencyCode: cartData?.data.currency,
        checkout: {
          actionField: {
            step: 2,
          },
          products: cartData?.data.lineItems.map(lineItem => ({
            id: lineItem?.offerId,
            name: lineItem?.name,
            price: parsePrice(lineItem?.originalPrice),
            brand: lineItem?.brandName,
            quantity: lineItem?.quantity,
          })),
        },
      },
    },
  ]);

  if (process.env.NEXT_PUBLIC_GA4_FEATURE_FLAG) {
    const sanitized = sanitizeCustomerData({ email });

    const payload = {
      event: 'add_contact_info',
      ecommerce: {
        currency: cartData?.data.currency,
        value: parsePrice(cartData?.data.total),
        coupon: cartData?.data.discountCode,
        payment_type: paymentMethod,
        checkout_location: checkoutLocation,
        items: buildLineItems(cartData?.data.lineItems),
      },
      user_data: {
        email: sanitized.email,
      },
    };

    if (leadId) {
      payload.velocity_lead_id = leadId;
    }

    event([
      {
        ecommerce: null,
      },
      payload,
    ]);
  }
}

function buildUserData(data) {
  return {
    email: data?.email,
    phone_number: data?.phone,
    address: {
      first_name: data?.firstName,
      last_name: data?.lastName,
      street: data?.street,
      city: data?.city,
      region: data?.regionCode,
      postal_code: data?.postalCode,
      country: data?.countryCode,
    },
  };
}

// On 2-step checkout: fired when user clicks "continue to payment"
// On 1-step checkout: fired when user clicks "buy now"
export function onAddShippingInfo({
  cartData,
  paymentMethod = OrderPaymentMethod.CREDIT_CARD,
  checkoutLocation,
  checkoutType,
  customerData,
}) {
  if (process.env.NEXT_PUBLIC_GA4_FEATURE_FLAG) {
    event([
      {
        ecommerce: null,
      },
      {
        event: 'add_shipping_info',
        ecommerce: {
          currency: cartData?.data.currency,
          value: parsePrice(cartData?.data.total),
          coupon: cartData?.data.discountCode,
          payment_type: paymentMethod,
          checkout_location: checkoutLocation,
          checkout_type: checkoutType,
          items: buildLineItems(cartData?.data.lineItems),
        },
        user_data: customerData ? buildUserData(sanitizeCustomerData(customerData)) : null,
      },
    ]);
  }
}

export function onAddPaymentInfo({ paymentMethod, cartData, checkoutLocation, customerData }) {
  // TODO: remove GA3 event after lukas has fully converted to using GA4 events
  event([
    {
      event: 'dl_attempt_payment',
      payment_type: paymentMethod,
      ecommerce: {
        checkout: {
          actionField: {
            step: '3',
          },
        },
      },
    },
  ]);

  if (process.env.NEXT_PUBLIC_GA4_FEATURE_FLAG) {
    event([
      {
        ecommerce: null,
      },
      {
        event: 'add_payment_info',
        ecommerce: {
          currency: cartData?.data.currency,
          value: parsePrice(cartData?.data.total),
          coupon: cartData?.data.discountCode,
          payment_type: paymentMethod,
          checkout_location: checkoutLocation,
          items: buildLineItems(cartData?.data.lineItems),
        },
        user_data: customerData ? buildUserData(sanitizeCustomerData(customerData)) : null,
      },
    ]);
  }
}

export function onPurchase({ orderId, cartData, purchaseType, customerData, paymentMethod }) {
  if (!Object.values(PURCHASE_TYPES).includes(purchaseType)) {
    throw new Error(`onPurchase: Invalid purchaseType ${purchaseType}`);
  }

  const sanitized = sanitizeCustomerData(customerData);

  // TODO: remove GA3 event after lukas has fully converted to using GA4 events
  event([
    {
      ecommerce: null,
    },
    {
      event: 'dl_purchase',
      payment_type: paymentMethod,
      ecommerce: {
        currencyCode: cartData?.data.currency,
        purchase: {
          actionField: {
            id: orderId,
            affiliation: 'Velocity',
            // Total value (incl. tax, discounts and shipping)
            revenue: cartData?.data.total,
            tax: 0,
            shipping: 0,
            coupon: cartData?.data.discountCode,
          },
          products: cartData?.data.lineItems.map(lineItem => ({
            id: lineItem?.offerId,
            name: lineItem?.name,
            // Price (each) including discount
            price: parsePrice(lineItem?.price),
            brand: lineItem?.brandName,
            quantity: lineItem?.quantity,
            coupon: lineItem?.discountCode,
            dimension2: purchaseType,
            metric1: lineItem?.metric1,
          })),
          user_properties: {
            customer_email: sanitized.email,
            customer_phone_number: sanitized.phone,
            customer_first_name: sanitized.firstName,
            customer_last_name: sanitized.lastName,
            customer_city: sanitized.city,
            customer_region_code: sanitized.regionCode,
            customer_postal_code: sanitized.postalCode,
            customer_country_code: sanitized.countryCode,
          },
        },
      },
    },
  ]);

  if (process.env.NEXT_PUBLIC_GA4_FEATURE_FLAG) {
    event([
      {
        ecommerce: null,
      },
      {
        event: 'purchase',
        ecommerce: {
          transaction_id: orderId,
          affiliation: 'Velocity',
          value: parsePrice(cartData?.data.total),
          tax: 0,
          shipping: 0,
          currency: cartData?.data.currency,
          coupon: cartData?.data.discountCode,
          transaction_type: purchaseType,
          payment_type: paymentMethod,
          items: cartData?.data.lineItems.map(lineItem => ({
            item_id: lineItem?.offerId,
            item_name: lineItem?.name,
            affiliation: 'Velocity',
            coupon: lineItem?.discountCode,
            discount: parsePrice(lineItem?.discountAmount),
            index: lineItem?.itemListIndex,
            item_list_id: lineItem?.itemListId,
            item_brand: lineItem?.brandName,
            price: parsePrice(lineItem?.price),
            quantity: lineItem?.quantity,
            offer_type: purchaseType,
            metric_1: Number(lineItem?.metric1 || 0),
          })),
        },
        user_data: buildUserData(sanitized),
      },
    ]);
  }
}

// Should be fired when user submit "Save This"
export function onSavePromotion(email, phone, offerData, leadId) {
  const payload = {
    event: 'generate_lead',
    ecommerce: {
      currency: offerData?.data?.[0]?.store?.currency,
      value: getHighestValue(offerData?.data),
    },
    user_data: {
      email: email ? sanitizeEmail(email) : null,
      phone_number: phone ? sanitizePhoneNumber(phone) : null, // formatted to FB specification
    },
  };

  if (leadId) {
    payload.velocity_lead_id = leadId;
  }

  event([
    payload,
    {
      event: 'form_submit',
      form_name: 'Save Promotion',
      form_id: email ? 'email' : 'phone',
    },
  ]);
}

// Should be fired when user focus's on "Save This"
export function onFocusPromotion(form_id) {
  event([
    {
      event: 'form_start',
      form_name: 'Save Promotion',
      form_id,
    },
  ]);
}

export function onFooterSignup(email, phone, offerData, leadId) {
  const payload = {
    event: 'generate_lead',
    ecommerce: {
      currency: offerData?.data?.[0]?.store?.currency,
      value: getHighestValue(offerData?.data),
    },
    user_data: {
      email: email ? sanitizeEmail(email) : null,
      phone_number: phone ? sanitizePhoneNumber(phone) : null, // formatted to FB specification
    },
  };

  if (leadId) {
    payload.velocity_lead_id = leadId;
  }

  event([
    payload,
    {
      event: 'form_submit',
      form_name: 'Footer Newsletter Signup',
      form_id: email ? 'email' : 'phone',
    },
  ]);
}

export function onFocusFooter(form_id) {
  event([
    {
      event: 'form_start',
      form_name: 'Footer Newsletter Signup',
      form_id,
    },
  ]);
}

export function getPageTypeFromCateogry(category) {
  switch (category.toLowerCase()) {
    case 'articles':
    case 'reviews':
      return PAGE_TYPES.EDITORIAL_PAGE;
    case 'the lists':
      return PAGE_TYPES.LISTICLE_PAGE;
  }
}

export function getMerchantTypeFromCateogry(category, merchantName) {
  category = category?.toLowerCase();

  if (category === 'articles' || category === 'reviews') {
    return merchantName;
  }

  return undefined;
}
