export function roundPrice(price) {
  return Math.round(price * 100) / 100;
}

export function parsePrice(price) {
  return roundPrice(Number(price));
}

export function convertPrice(price, rate) {
  return parsePrice(price * (rate || 1));
}

/**
 * @param {String} price - A price string formatted with two decimal points "1.50"
 */
export function priceToCents(price) {
  // Remove the decimal place from the dollars string to convert to cents.
  return `${price?.slice(0, price?.length - 3)}${price?.slice(price?.length - 2)}`;
}

export function getOfferUnitPrice(price, quantity) {
  return roundPrice(parsePrice(price) / quantity);
}

// Function assumes Afterpay USD collection schedule (4 payments)
// https://stripe.com/docs/payments/afterpay-clearpay#collection-schedule
export function getAfterpayInstallmentPrice(price) {
  return roundPrice(parsePrice(price) / 4);
}

export function getAmountSaved(originalPrice, price) {
  return roundPrice(originalPrice - Number(price));
}

export function getPercentSaved(originalPrice, price) {
  const savings = getAmountSaved(originalPrice, price);

  if (!savings) return 0;
  return Math.round((savings / originalPrice) * 100);
}
