function removePunctuation(str = '') {
  return str.replace(/['!"#$%&\\'()*+,\-./:;<=>?@[\]^_`{|}~']/g, '');
}

function removePunctuationAndWhiteSpace(str = '') {
  return str.replace(/['!"#$%&\\'()*+,\-./:;<=>?@[\]^_`{|}~'\s]/g, '');
}

function encodeUtf8(str = '') {
  return Buffer.from(str).toString('utf8');
}

function formatPostalCode(postalCode, country) {
  const formatted = removePunctuationAndWhiteSpace(postalCode).toLowerCase();

  if (country === 'US') {
    return formatted.slice(0, 5);
  }

  // TODO: UK should use Area/District/Sector format

  return formatted;
}

export function sanitizeEmail(email) {
  return email?.trim().toLowerCase();
}

export function sanitizePhoneNumber(phone) {
  // TODO: This needs improvement to handle phone numbers other than US/CAN
  // see: https://developers.facebook.com/docs/marketing-api/conversions-api/parameters/customer-information-parameters
  return phone?.replace(/\+/g, '');
}

// Requirements for FB CAPI data: https://developers.facebook.com/docs/marketing-api/conversions-api/parameters/customer-information-parameters
export function sanitizeCustomerData(data) {
  return {
    email: sanitizeEmail(data?.email),
    phone: sanitizePhoneNumber(data?.phone),
    firstName: encodeUtf8(removePunctuation(data?.firstName).toLowerCase()),
    lastName: encodeUtf8(removePunctuation(data?.lastName).toLowerCase()),
    city: encodeUtf8(removePunctuationAndWhiteSpace(data?.shippingCity).toLowerCase()),
    regionCode: removePunctuationAndWhiteSpace(data?.shippingState).toLowerCase(),
    postalCode: formatPostalCode(data?.shippingZip, data?.shippingCountry),
    countryCode: data?.shippingCountry?.toLowerCase(),
    street: encodeUtf8(data?.shippingAddress?.toLowerCase()), // Not used by FB, but added to support GA4 - Google Ads enhanced conversions
  };
}
